import React from 'react';
import PrimaryRouter from './router/primary.routes';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
function App() {
  return (
    <div className="App" style={{ height: '100%' }}>
      <BrowserRouter>
        <PrimaryRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
