import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import AboutUsPage from '../pages/AboutUsPage';
import LandingPage from '../pages/LandingPage';

const PrimaryRouter = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location]);

  return (
    <>
      <ToastContainer transition={Slide} position="bottom-center" />
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
      <Routes>
        <Route path="/about-us" element={<AboutUsPage />} />
      </Routes>
    </>
  );
};

export default PrimaryRouter;
