import React from 'react';
import { Link } from 'react-router-dom';
import * as assets from '../assets/assets';
import landingService1 from '../assets/images/landingService1.jpg';
import landingService2 from '../assets/images/landingService2.jpg';
import landingService3 from '../assets/images/landingService3.jpg';
import landingServiceButton from '../assets/images/Group.svg';
import CheckUp2 from '../assets/images/CheckUp2.jpg';
import CheckUp3 from '../assets/images/CheckUp3.jpg';
import CheckUp1 from '../assets/images/CheckUp1.jpg';
import GooglePlusIcon from '../assets/icons/googleplus.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import PintrestIcon from '../assets/icons/pintrest.svg';
import InstagramIcon from '../assets/icons/instagram.svg';
import TwitterIcon from '../assets/icons/twitter.svg';

interface LoginCardData {
  icon: any;
  title: string;
  route: string;
}

const loginCardData: LoginCardData[] = [
  {
    icon: assets.icons.MidwifeIcon,
    title: 'Midwife',
    route: 'https://smartbpmonitor.pragava.health/login',
  },
  {
    icon: assets.icons.DoctorIcon,
    title: 'Doctor',
    route: 'https://smartbpmonitor.pragava.health/login',
  },
  {
    icon: assets.icons.HospitalIcon,
    title: 'Admin',
    route: 'https://smartbpmonitor.pragava.health/admin-login',
  },
];

const LandingPage = () => {
  return (
    <div>
      <nav className="bg-white flex justify-between shadow-xl py-6 px-24">
        <div className="flex flex-row divide-x">
          <img src={assets.branding.AppLogo} className="pr-6" />
          
          <img src={assets.branding.PragavaLogo} className="pl-6" />
        </div>
        <a href="https://smartbpmonitor.pragava.health/" target="_blank" rel="noreferrer" className="my-auto">
          <div className="flex uppercase my-auto">
            Signup
            <img src={assets.icons.SignupIcon} className="ml-1.5" />
          </div>
        </a>
      </nav>
      <div className="landing-bg w-full relative" style={{ height: '70vh' }}>
        {/* Gradient */}
        <div className="landing-bg-gradient w-full h-full" />
        {/* Container Content */}
        <div className="h-full w-full absolute top-2/4 bottom-0 px-24 my-auto">
          <div className="">
            <div className="bg-primaryGreen bg-opacity-40 text-primaryGreen capitalize w-max rounded-md text-sm px-2 py-1 font-semibold">
              Higher Level of care
            </div>
            <div className="text-black font-semibold text-5xl mt-2">Advancing Diagnostics in Pregnancy</div>
            <div className="mt-8">
              <a href="#knowMoreSection">
                <button className="bg-primaryOrange shadow-md rounded-md px-6 py-3 text-white font-semibold capitalize">
                  Know more
                </button>
              </a>
            </div>
          </div>
          {/* Login Cards */}
          <div className="absolute bottom-14 flex flex-row z-40">
            {loginCardData.map((data: LoginCardData) => (
              <div className="bg-primaryGreen rounded-md p-12  shadow-lg mr-7" key={data.route + data.title}>
                <div className="flex flex-row justify-center h-full items-center">
                  <div className="mr-2.5">
                    <img src={data.icon} />
                  </div>
                  <div>
                    <div className="text-4xl text-white">{data.title}</div>
                    <div className="mt-2">
                      <a href={data.route} target="_blank" rel="noreferrer" className="cursor-pointer">
                        <button className="bg-black bg-opacity-20 shadow-sm text-sm text-white rounded-md px-2 py-1 flex flex-row">
                          Login
                          <img src={assets.icons.ArrowRight} className="my-auto ml-1" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className=" w-full relative text-center px-24"
        style={{ height: 'auto', paddingTop: '20vh', paddingBottom: '10vh' }}
      >
        <div className="text-black font-semibold text-5xl mt-2 text-left" id="knowMoreSection">
          Pragava.health SmartBP Monitor
        </div>
        <div className="grid place-items-left py-3 mt-3">
          <div className="bg-primaryGreen bg-opacity-40 text-primaryGreen capitalize w-max rounded-md text-sm px-2 py-1 font-semibold">
            About Us
          </div>
        </div>
        <p className="text-sm text-gray-500 text-left pt-4 pb-7">
          Blood pressure monitoring is a vital part of a woman&apos;s journey through pregnancy.
          <br />
          Pragava.health SmartBP Monito offers and easy, safe and reliable way for you to record and share your blood
          pressure readings taken as directed by your medical professional
          <br />
          <br />
          Pragava.health is committed to support mothers to be and their health care providers to record and access
          reliable data for blood pressure ensuring appropriate information is available. Pragava.health builds systems
          enabling safe, effective and secure recording and analysis of healthcare data. Pragava.health brings unique
          capabilities melding AI and health care data to provide insights to licensed medical professionals assistance
          in diagnostics. Our primary goal is to help drive better outcomes for patients , enhance affordability of
          healthcare and provide best in class healthcare analytics to professionals Pragava.health was founded by
          former Microsoft engineers with the experience of engineering complex and scalable platforms. Pragava.health
          is based in London, Seattle and Pune.
        </p>
      </div>
      <div className=" text-center px-24" style={{ height: 'auto' }}>
        <div className="text-black font-semibold text-5xl mt-2 text-center">Product Features</div>
        <div className="grid place-items-center py-3 mt-3">
          <div className="bg-primaryGreen bg-opacity-40 text-primaryGreen capitalize w-max rounded-md text-sm px-2 py-1 font-semibold">
            Features
          </div>
        </div>
        <div className="grid grid-rows-2 grid-flow-col gap-4 pt-10 pb-20 px-20">
          <div className="row-span-2 col-span-2  rounded-lg bg-gray-100 px-3 py-3">
            <img className="t-lg" src={landingService1} alt="" />
            <div className="p-5 text-left flex justify-between">
              <div className="">
                <p className="font-bold text-primaryGreen ">Feature</p>
                <p className="text-2xl tracking-tight text-black">Color coded flagging</p>
              </div>
              <div className="py-2">
                <button>
                  <a
                    href="https://www.nice.org.uk/guidance/ng133/chapter/Recommendations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={landingServiceButton} className="my-auto ml-1" />
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className=" row-span-2 col-span-2 rounded-lg bg-gray-100 px-3 py-3">
            <img className="t-lg" src={landingService2} alt="" />
            <div className="p-5 text-left flex justify-between">
              <div className="">
                <p className="font-bold text-primaryGreen ">Feature</p>
                <p className="text-2xl tracking-tight text-black">Provider access</p>
              </div>
              <div className="py-2">
                {/* <button>
                  <img src={landingServiceButton} className="my-auto ml-1" />
                </button> */}
              </div>
            </div>
          </div>
          <div className=" row-span-2 col-span-2 rounded-lg bg-gray-100 px-3 py-3">
            <img className="t-lg" src={landingService3} alt="" />
            <div className="p-5 text-left flex justify-between">
              <div className="">
                <p className="font-bold text-primaryGreen ">Feature</p>
                <p className="text-2xl tracking-tight text-black">Recording app on iOS and Android</p>
              </div>
              <div className="py-2">
                {/* <button>
                  <img src={landingServiceButton} className="my-auto ml-1" />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="landing-bg_2 w-full relative" style={{ height: '70vh' }}>
        <div className="landing-bg_2-gradient w-full h-full" />
        <div className="h-full w-full absolute top-2/4 bottom-0 px-24 my-auto">
          <div className="">
            <div className="text-primaryGreen font-semibold text-5xl mt-2">
              Effective methods for <br /> Blood Pressure
            </div>
            <p className="text-black text-1xl py-10">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              <br /> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud <br />
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              <br /> dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              <br /> Excepteur sint occaecat cupidatat non proident,
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className="grid grid-cols-2 gap-4 py-24">
        <div className="px-24 py-15">
          <img src={CheckUp1} style={{ maxHeight: '427px' }} />
        </div>
        <div className="px-10">
          <div className="text-black text-4xl py-4">
            Regualar Check-ups <br /> for patients
          </div>
          <div className="bg-primaryGreen bg-opacity-40 text-primaryGreen capitalize w-max rounded-md text-sm px-2 py-1 font-semibold">
            Checkups
          </div>
          <div className="grid grid-cols-24 py-10">
            <div>
              <img src={CheckUp2} width="25px" />
            </div>
            <div className="col-start-2 col-end-12 text-lg text-orange-400">
              Effective Treatment
              <br />
              <span className="text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                <br /> tempor incididunt ut labore et dolore magna aliqua.
              </span>
            </div>
          </div>
          <div className="grid grid-cols-24">
            <div>
              <img src={CheckUp3} width="25px" />
            </div>
            <div className="col-start-2 col-end-12 text-lg text-orange-400">
              Complex Approach
              <br />
              <span className="text-sm text-gray-500">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                <br /> tempor incididunt ut labore et dolore magna aliqua.
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div className="grid grid-cols-3 gap-4 py-5 bg-stone-800">
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-lg ">Questions? Call us!</div>
          <div className="text-gray-400 text-sm">
            Do you have any questions? Send us an e-mail and we will reply to you as soon as possible.
          </div>
          <div className="text-white text-xl py-2">contact@pragava.health</div>
          <p className="text-gray-400 text-sm">You can also Call us at</p>
          <p className="text-white text-2xl">
            <a href="tel:1-425-835-3454">1-425-835-3454</a>
          </p>
          <address className="not-italic">
            <p className="text-gray-400 text-sm">
              <p className="text-lg">Pragava Limited</p>
              <a className="font-semibold mt-1">USA:</a>
              <br />
              1414 2nd Ave, SEATTLE, WA 98101
              <a className="font-semibold mt-2 block">UK:</a>
              71-75 Shelton Street Covent Garden London WC2H 9JQ UNITED KINGDOM
            </p>
          </address>
        </div>
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-base py-3 ">Terms and Conditions</div>
          <div className="text-orange-400 font-semibold text-base py-3 ">Privacy Policies</div>
          <div className="text-orange-400 font-semibold text-base py-3 ">
            <a href={'/about-us'}>About Us</a>
          </div>
        </div>
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-base py-3 ">Connect us on</div>
          <div className="flex">
            <button>
              <img src={FacebookIcon} className="px-3" />
            </button>
            <button>
              <img src={TwitterIcon} className="px-3" />
            </button>
            <button>
              <img src={InstagramIcon} className="px-3" />
            </button>
            <button>
              <img src={PintrestIcon} className="px-3" />
            </button>
            <button>
              <img src={GooglePlusIcon} className="px-3" />
            </button>
          </div>
        </div>
      </div>

      <div className="grid place-items-center py-5 bg-stone-900">
        <div className="text-white text-sm py-3">Copyright &copy; 2022-2023 Pragava LLC</div>
      </div>
    </div>
  );
};

export default LandingPage;
