import React from 'react';
import { Link } from 'react-router-dom';
import * as assets from '../assets/assets';
import landingService1 from '../assets/images/landingService1.jpg';
import landingService2 from '../assets/images/landingService2.jpg';
import landingService3 from '../assets/images/landingService3.jpg';
import landingServiceButton from '../assets/images/Group.svg';
import CheckUp2 from '../assets/images/CheckUp2.jpg';
import CheckUp3 from '../assets/images/CheckUp3.jpg';
import CheckUp1 from '../assets/images/CheckUp1.jpg';
import GooglePlusIcon from '../assets/icons/googleplus.svg';
import FacebookIcon from '../assets/icons/facebook.svg';
import PintrestIcon from '../assets/icons/pintrest.svg';
import InstagramIcon from '../assets/icons/instagram.svg';
import TwitterIcon from '../assets/icons/twitter.svg';

const AboutUsPage = () => {
  return (
    <div>
      <nav className="bg-white flex justify-between shadow-xl py-6 px-24">
        <div className="flex flex-row divide-x">
          <img src={assets.branding.AppLogo} className="pr-6" />

          <img src={assets.branding.PragavaLogo} className="pl-6" />
        </div>
        <a href="https://smartbpmonitor.pragava.health/" target="_blank" rel="noreferrer" className="my-auto">
          <div className="flex uppercase my-auto">
            Signup
            <img src={assets.icons.SignupIcon} className="ml-1.5" />
          </div>
        </a>
      </nav>

      <div className="bg-primaryGreen px-24 py-24 text-white capitalize text-4xl font-semibold">About Us</div>

      <div className="px-24 py-12 min-h-screen">
        <span className="text-xl">Pragava</span> was founded with a goal to revolutionize healthcare by blending AI,
        hardware and state of the art software. We build and operate a platform for health care that aims to help
        patients get information at their fingertips, enable healthcare professionals make the right decisions and
        improve outcomes.
        <br />
        <br />
        <a className="text-xl">Pradeep Parappil, Co-founder</a>
        <br />
        Pragava was founded by Pradeep Parappil. Pradeep Parappil spent a decade and half at Microsoft working on Azure
        and Windows engineering teams. He has a Bachelors in Engineering (Computer Science), EEAP from Wharton and an
        MBA from INSEAD, Fontainebleau, France.
      </div>

      <div className="grid grid-cols-3 gap-4 py-5 bg-stone-800">
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-lg ">Questions? Call us!</div>
          <div className="text-gray-400 text-sm">
            Do you have any questions? Send us an e-mail and we will reply to you as soon as possible.
          </div>
          <div className="text-white text-xl py-2">contact@pragava.health</div>
          <p className="text-gray-400 text-sm">You can also Call us at</p>
          <p className="text-white text-2xl">
            <a href="tel:1-425-835-3454">1-425-835-3454</a>
          </p>
          <address className="not-italic">
            <p className="text-gray-400 text-sm">
              <p className="text-lg">Pragava Limited</p>
              <a className="font-semibold mt-1">USA:</a>
              <br />
              1414 2nd Ave, SEATTLE, WA 98101
              <a className="font-semibold mt-2 block">UK:</a>
              71-75 Shelton Street Covent Garden London WC2H 9JQ UNITED KINGDOM
            </p>
          </address>
        </div>
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-base py-3 ">Terms and Conditions</div>
          <div className="text-orange-400 font-semibold text-base py-3 ">Privacy Policies</div>
          <div className="text-orange-400 font-semibold text-base py-3 ">
            <Link to={'/about-us'}>About Us</Link>
          </div>
        </div>
        <div className="px-24">
          <div className="text-orange-400 font-semibold text-base py-3 ">Connect us on</div>
          <div className="flex">
            <button>
              <img src={FacebookIcon} className="px-3" />
            </button>
            <button>
              <img src={TwitterIcon} className="px-3" />
            </button>
            <button>
              <img src={InstagramIcon} className="px-3" />
            </button>
            <button>
              <img src={PintrestIcon} className="px-3" />
            </button>
            <button>
              <img src={GooglePlusIcon} className="px-3" />
            </button>
          </div>
        </div>
      </div>

      <div className="grid place-items-center py-5 bg-stone-900">
        <div className="text-white text-sm py-3">Copyright &copy; 2022-2023 Pragava LLC</div>
      </div>
    </div>
  );
};

export default AboutUsPage;
