// Import icons/images
import AppLogo from './branding/app-logo.png';
import CloseIcon from './icons/close-icon.svg';
import Doctor from './branding/doctor.jpg';
import AdminLogin from './branding/adminlogin.jpg';
import SearchIcon from './icons/search-icon.svg';
import CalendarIcon from './icons/calendar-icon.svg';
import ClockIcon from './icons/clock-icon.svg';
import HorizontalLine from './icons/horizontalLine.png';
import AdminBG from './branding/adminBG.jpg';
import HospitalIcon from './icons/hospital-icon.svg';
import DoctorIcon from './icons/doctor-icon.svg';
import MidwifeIcon from './icons/midwife-icon.svg';
import ArrowRight from './icons/arrow-right.svg';
import SignupIcon from './icons/signup-icon.svg';
import PragavaLogo from './branding/pragava-logo.svg';

export const branding = {
  AppLogo,
  Doctor,
  AdminBG,
  PragavaLogo,
};

export const icons = {
  CloseIcon,
  SearchIcon,
  CalendarIcon,
  ClockIcon,
  HorizontalLine,
  HospitalIcon,
  DoctorIcon,
  MidwifeIcon,
  ArrowRight,
  SignupIcon,
};

export const placeholders = {};
